<nav class="navbar bg-light sticky-top" id="admin-layout">
  <div class="container-fluid">
    <button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="offcanvas"
      data-bs-target="#offcanvasNavbar"
      aria-controls="offcanvasNavbar"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div
      class="offcanvas offcanvas-start text-bg-dark"
      tabindex="-1"
      id="offcanvasNavbar"
      aria-labelledby="offcanvasNavbarLabel"
    >
      <div class="offcanvas-header">
        <h5 class="offcanvas-title" id="offcanvasNavbarLabel">
          Yönetim Paneli
        </h5>
        <button
          type="button"
          class="btn-close btn-close-white"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div class="offcanvas-body">
        <ul class="list-unstyled mb-0 pb-3 pb-md-2 pe-lg-2">
          <li>
            <strong class="d-flex w-100 align-items-center fw-semibold" data-bs-toggle="dropdown" aria-expanded="false">
              Ürün İşlemleri
            </strong>
            <hr />
            <div class="dropdown w-100">
              <ul class="dropdown-menu dropdown-menu-dark">
                <li>
                  <strong class="d-flex w-100 align-items-center fw-semibold">
                    <a routerLink="products" class="nav-link">Ürünler </a>
                  </strong>
                </li>
                  <li>
                    <strong class="d-flex w-100 align-items-center fw-semibold">
                      <a routerLink="comments" class="nav-link">Ürün Yorumları </a>
                    </strong>
                  </li>
                <li>
                <strong class="d-flex w-100 align-items-center fw-semibold">
                  <a routerLink="questions" class="nav-link">Ürün Soruları </a>
                </strong>
                </li>
                <li>
                  <strong class="d-flex w-100 align-items-center fw-semibold">
                    <a routerLink="attribute" class="nav-link"> Ürün varyasyon </a>
                  </strong>
                </li>
              </ul>
            </div>
          </li>
          @for (n of nameList; track $index) {
            <li>
              <strong class="d-flex w-100 align-items-center fw-semibold"
                ><a [routerLink]="n.routerLink" aria-label="Close" class="nav-link">
                {{n.name}}
                </a>
              </strong>
              <hr />
            </li>
          }


        </ul>
      </div>
    </div>
  </div>
</nav>
<div>
  <router-outlet></router-outlet>
</div>
