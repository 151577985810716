<router-outlet></router-outlet>
<ngx-spinner size="default" name="s1" type="ball-atom"></ngx-spinner>
<ngx-spinner size="default" name="s2" type="ball-scale-multiple"></ngx-spinner>
<ngx-spinner
  size="default"
  name="s3"
  type="ball-spin-clockwise-fade-rotating"
></ngx-spinner>
<ngx-spinner
  size="default"
  color="#fff"
  type="line-scale-pulse-out"
  [fullScreen]="true"
  name="s4"
  ><p style="color: white">Yükleniyor...</p></ngx-spinner
>
